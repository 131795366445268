<template>
  <div class="services-select">
    <el-select
      v-model="syncModel"
      :multiple="multiple"
      :disabled="disabled"
      value-key="id"
      collapse-tags
      :placeholder="placeholderText"
      no-data-text="暫無數據"
      @change="$emit('change', serviceList)"
    >
      <el-option
        v-for="item in serviceList"
        :key="item.id"
        :label="item.name"
        :value="item"
      />
    </el-select>
  </div>
</template>

<script>
import { FindServiceUnit } from '@/api/serviceUnit'
import { GetService, GetServiceCount } from '@/api/service'
import { mapGetters } from 'vuex'
export default {
  name: 'ServiceSelect',
  props: ['model', 'multiple', 'unitId', 'showAll', 'disabled', 'placeholder', 'isResourceBind'],
  data: () => ({
    serviceList: [],
  }),

  computed: {
    ...mapGetters(['shop']),
    syncModel: {
      get () {
        return this.model
      },
      set (data) {
        this.$emit('update:model', data)
      },
    },

    placeholderText () {
      let text = '請選擇服務'
      if (this.showAll) return text
      if (this.placeholder) text = this.placeholder
      return text
    },
  },
  watch: {
    unitId () {
      this.findServiceUnit()
    },
  },
  async mounted () {
    if (this.showAll) {
      await this.getAllService()
      return
    }
    if (this.isResourceBind) {
      await this.getServiceItemResourceBind()
    }
    await this.findServiceUnit()
  },

  methods: {
    sortServiceList () {
      this.serviceList.sort((a, b) => {
        if (a.order > b.order) return 1
        if (a.order < b.order) return -1
      })
    },
    async findServiceUnit () {
      if (this.unitId === '' || this.unitId === undefined) return
      try {
        const res = await FindServiceUnit({
          shopId: this.shop,
          id: this.unitId,
        })

        this.serviceList = res.AppointmentServices
        this.sortServiceList()
      } catch (error) {
        console.log(error)
      }
    },
    async getServiceItemResourceBind () {
      const options = {
        shopId: this.shop,
        start: 0,
        limit: 100,
      }
      const res = await GetService(options)
      const resourceBindList = res.filter(item => item.enableResourceItem)
      console.log('resourceBindList:', res, resourceBindList)
      this.serviceList = resourceBindList
    },
    async getAllService () {
      try {
        let max = await this.getServiceCount()
        max = max.data.count

        const options = {
          shopId: this.shop,
          start: 0,
          limit: 100,
        }
        const res = await GetService(options)
        console.log(res)
        this.serviceList = res
        while (this.serviceList.length < max) {
          options.start += options.limit
          const res = await GetService(options)
          this.serviceList.push(...res)
          console.log('serviceList', this.serviceList.length)
        }
        this.sortServiceList()
      } catch (error) {
        console.log(error)
      }
    },

    async getServiceCount () {
      try {
        return await GetServiceCount({ shopId: this.shop })
      } catch (error) {
        console.log(error)
      }
    },
  },
}
</script>

<style scoped lang="scss"></style>
